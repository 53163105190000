/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */

import Mapping from '../configs/Mapping'
import Api from '@/configs/api.js'
import numeral from 'numeral'
import { BiMap } from './Class'
import deepClone from 'lodash/cloneDeep'
import app from '../main.js'

function myLib() {
  /*
    isAdmin  鲸云总管理员
    isCust   鲸云客户端
    isSup    鲸云供应商端
  */
  let isAdmin = false
  let isCust = false
  let isSup = false
  let isCustSpm = false
  let isCustPm = false
  let isSupSpm = false
  let isSupPm = false
  let isOrderSpm = false
  let isOrderer = false
  let _roleType = ''
  let customers = {
    Rockwell: undefined,
    Stone: undefined,
    Kingsoft: undefined,
  }
  const Routes = {
    order_newOrder: '/order/newOrder',
    order_orderInfo: '/order/orderInfo',
    home: '/home',
    coConfig_home: '/coConfig/home',
    project_list: '/project/list',
    customerConfig_productLineDetail: '/customerConfig/productLineDetail',
    payment: '/payment',
  }

  const order_manager_info_template =
    'https://genplus-common.oss-cn-shanghai.aliyuncs.com/excel_template/%E4%B8%8B%E5%8D%95%E7%BB%8F%E7%90%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF-%E9%80%9A%E7%94%A8%E7%89%88.xlsx'
  const deliver_manager_info_template =
    'https://genplus-common.oss-cn-shanghai.aliyuncs.com/excel_template/%E9%B2%B8%E4%BA%91-%E4%BA%A4%E4%BB%98%E7%BB%8F%E7%90%86%E6%A8%A1%E6%9D%BF.xlsx'
  const price_list_info_template =
    'https://genplus-common.oss-cn-shanghai.aliyuncs.com/excel_template/%E4%BB%B7%E6%A0%BC%E6%A8%A1%E6%9D%BF.xlsx'

  const setData = ({ userInfo, customizedCustomerList }) => {
    const { roleType, cusAgencyId } = userInfo
    // 通过cusAgencyId去拿cusName
    const cusName = getCusName(customizedCustomerList, cusAgencyId)

    for (const key in customers) {
      cusName === key && (customers[key] = true)
    }

    _roleType = roleType
    if (noEmpty(roleType)) {
      // 供本js内部使用
      isAdmin = roleType === 99
      isCust = roleType > 20 && roleType < 30
      isSup = roleType > 30 && roleType < 40
      isSupSpm = roleType === 31
      isSupPm = roleType === 32
      isCustSpm = roleType === 21
      isCustPm = roleType === 22
      isOrderSpm = roleType === 23
      isOrderer = roleType === 24
      // 供外部文件使用
      allFuncs.isAdmin = isAdmin
      allFuncs.isCust = isCust
      allFuncs.isSup = isSup
      allFuncs.isSupSpm = isSupSpm
      allFuncs.isSupPm = isSupPm
      allFuncs.isCustSpm = isCustSpm
      allFuncs.isCustPm = isCustPm
      allFuncs.isOrderSpm = isOrderSpm
      allFuncs.isOrderer = isOrderer
    }

    if (noEmptys(cusName)) {
      if (Mapping.has(cusName)) {
        const obj = Mapping.get(cusName)
        const { mounts } = obj
        for (const [key, val] of Object.entries(mounts)) {
          allFuncs[key] = val
        }

        const { api } = obj

        const Apis = Object.keys(Api)
        const apis = Object.keys(api)

        const sources = getMatched(Apis, apis)

        // 当source为true时，证明有需要替换路由和接口的定制化id
        if (sources.length) {
          for (const source of sources) {
            // 获取目标键
            const target = api[source]
            // 替换为目标值
            Api[source] = Api[target]
          }
        }
      }
    }

    console.log(`allFuncs`, allFuncs)
  }

  const getMatched = (parentArr, childrenArr) => {
    let target = []
    parentArr.forEach((v) => {
      if (childrenArr.includes(v)) {
        target.push(v)
      }
    })

    return target
  }
  const getCusName = (customizedCustomerList, cusAgencyId) => {
    const target = customizedCustomerList.find((_) => _.dictValue.includes(cusAgencyId))
    return target?.remark
  }

  // 在这里根据条件修改路由的值
  const modifyRoute = ({ nav, customizedCustomerList, cusAgencyId }) => {
    return new Promise((resolve, reject) => {
      const cusName = getCusName(customizedCustomerList, cusAgencyId)

      if (cusName && Mapping.has(cusName)) {
        const { route, routeKey } = Mapping.get(cusName)
        const sourceR = Object.keys(route)
        const replaceUrl = (obj) => {
          if (sourceR.includes(obj.url)) {
            let target = ''
            sourceR.forEach((item, idx) => {
              if (item === obj.url) {
                target = routeKey[idx]
              }
            })
            obj.url = route[obj.url]
            if (target) {
              Routes[target] = `/${obj.url}`
            }
          }
        }
        nav.forEach((_) => {
          replaceUrl(_)
          const children = _.children
          if (children && children.length) {
            children.forEach((__) => {
              replaceUrl(__)
            })
          }
        })

        resolve(nav)
      } else {
        resolve(nav)
      }
    })
  }
  /*
    returnLogin 管理本系统所有地方的退回登录操作
  */
  const returnLogin = () => {
    if (isCust || isAdmin) {
      window.location.href = '/login'
    } else if (isSup) {
      window.location.href = '/coLogin'
    } else {
      window.location.href = '/login'
    }
  }

  const isEmptyObj = (val) => JSON.stringify(val) === '{}'

  const isEmptyArr = (val) => JSON.stringify(val) === '[]'

  const isEmpty = (val) => [undefined, null, ''].some((item) => item === val) || isEmptyObj(val) || isEmptyArr(val)

  const noEmpty = (val) => [undefined, null, ''].every((item) => item !== val) && !isEmptyObj(val) && !isEmptyArr(val)

  const defined = (val) => [undefined, null].excludes(val)

  const noEmptys = (...rest) => rest.every((item) => noEmpty(item))

  const zero = (val) => {
    return [undefined, null, '', 0, '0'].includes(val)
  }
  /**
   * @description: 数据类型的检测的方式
   * @param {any} data 要检测数据类型的变量
   * @param {string} type 具体的类型名称【小写】
   */
  const is = (type, data) => {
    return (
      Object.prototype.toString
        .call(data)
        .replace(/\[object (\w+)\]/, '$1')
        .toLowerCase() === type
    )
  }
  const type = (data) => {
    return Object.prototype.toString
      .call(data)
      .replace(/\[object (\w+)\]/, '$1')
      .toLowerCase()
  }
  const $$ = (str = '') => {
    if (str.charAt(0) == '#') {
      return document.getElementById(str.substring(1))
    } else if (str.charAt(0) == '.') {
      const classnode = document.getElementsByClassName(str.substring(1))
      return classnode.length > 1 ? classnode : classnode.length === 1 ? classnode[0] : null
    } else {
      const classnode = document.getElementsByName(str)
      return classnode.length > 1 ? classnode : classnode.length === 1 ? classnode[0] : null
    }
  }
  const allEqual = (arr) => arr.every((val) => val === arr[0])
  const allKeyEqual = (arr, key) => arr.every((val) => val[key] === arr[0][key])
  const elementContains = (parent, child) => parent !== child && parent.contains(child)

  const sortClass = (sortData = [{}], key = '') => {
    const groupBy = (array, f) => {
      let groups = {}
      array.forEach((o) => {
        let group = JSON.stringify(f(o))
        groups[group] = groups[group] || []
        groups[group].push(o)
      })
      return Object.keys(groups).map((group) => {
        return groups[group]
      })
    }
    const sorted = groupBy(sortData, (item) => {
      return item[key] // 返回需要分组的对象
    })
    return sorted
  }

  /**
   *  @description:   归档，对一维json数组进行归档（根据key）
   *  @param {Array}  arr:一维数组
   *  @param {String} key：key字符串
   */
  const sort = function (arr, key) {
    return [...new Set(arr.map((i) => i[key]))].reduce((p, c) => [...p, arr.filter((i) => i[key] === c)], [])
  }

  const unique_obj_arr = (arr, key) => {
    const res = new Map()
    return arr.filter((arr) => !res.has(arr[key]) && res.set(arr[key], 1))
  }

  const unique = (arr) => Array.from(new Set(arr))

  const flatten = (arr) => {
    while (arr.some((item) => Array.isArray(item))) {
      arr = [].concat(...arr)
    }
    return unique(arr)
  }

  const limitStr = (strval, strnum) => {
    let re = ''

    strval = strval || ''

    const strleng = strval.length
    // 返回字符串的总字节数
    const byteleng = strval.replace(/[^\x00-\xff]/g, '**').length

    if (byteleng <= strnum) return strval
    for (let i = 0, bytenum = 0; i < strleng; i++) {
      const byte = strval.charAt(i)

      if (/[\x00-\xff]/.test(byte)) {
        bytenum++ // 单字节字符累加1
      } else {
        bytenum += 2 // 非单字节字符累加2
      }
      if (bytenum <= strnum) {
        re += byte
      } else {
        return re
      }
    }
  }
  const strLen = (sString) => {
    var j = 0
    var s = sString
    if (s == '') return j
    for (var i = 0; i < s.length; i++) {
      if (s.substr(i, 1).charCodeAt(0) > 255) j = j + 2
      else j++
    }
    return j
  }

  const del = (arrylist, id) => {
    if (is('array', arrylist)) {
      const idx = arrylist.findIndex((item) => item.id === id)
      arrylist.splice(idx, 1)
    } else {
      console.log(`arrylist`, arrylist)
      console.error('!!! arrylist参数不是数组类型 !!!')
    }
  }

  const resetData = (self) => {
    setTimeout(() => {
      Object.assign(self.$data, self.$options.data())
    }, 300)
  }

  const tofixed2 = (count) => {
    return numeral(count).format('0.[00]')
  }

  const toFixed = (n, fixed) => ~~(Math.pow(10, fixed) * n) / Math.pow(10, fixed)
  // Examples
  // toFixed(25.198726354, 1) // 25.1
  // toFixed(25.198726354, 2) // 25.19
  // toFixed(25.198726354, 3) // 25.198

  const sum = (params) => {
    if (is('array', params)) {
      return params.reduce((pre, curr) => Number(pre) + Number(curr))
    } else {
      return Object.values(params).reduce((pre, curr) => Number(pre) + Number(curr))
    }
  }
  function includes(arr1, arr2) {
    return arr1.every((val) => arr2.includes(val))
  }
  /**
   * @description: 仿jq的find方法，寻找到符合条件的单个dom
   * @param {parent} object 父级dom
   * @param {label} string 可以为id、class、TagName
   *
   */
  // ~~~推荐使用findDom
  const findDom = (parent, label) => {
    const find = (_parent, _label) => {
      for (let i = 0; i < _parent.children.length; i++) {
        const item = _parent.children[i]
        const classList = Array.from(item.classList)
        const id = item.id.toLowerCase()
        const tagName = item.tagName.toLowerCase()

        if (classList.includes(_label) || [id, tagName].includes(_label)) {
          // 注意：只有使用for循环 return 才有效
          return item
        }
        find(item, _label)
      }
    }

    return find(parent, label)
  }

  /**
   * @description: 仿vue的watch方法，当传入的数组有值时，执行callback
   * @param {that} object vue的实例
   * @param {name} string 数组的名称
   * @param {callback} function 传入的回调函数
   */
  const watchVal = (that, name, callback) => {
    // count记录回调了多少次
    let count = 0

    const watchItem = () => {
      const item = that[name]
      if (item.length) {
        callback()
      } else {
        count++
        setTimeout(() => {
          watchItem()
        })
      }
    }
    watchItem()
  }

  const debounce = function (fn, interval) {
    // debounce中的interval 和 throttle中的 interval含义不一样
    // 在debounce中可以可以把interval理解成 用户停止了某个连续的操作后 再推迟interval执行fn
    var timerId = null

    return function () {
      var current = +new Date()
      var args = [].slice.call(arguments, 0)
      var context = this
      // 如果调用很密集 可以保证fn永远不会触发 必须等到有前后两个调用的间隔大于等于interval fn才能被执行
      // 如果调用很少 fn会在interval结束后被执行
      clearTimeout(timerId)
      timerId = setTimeout(function () {
        fn.apply(context, args)
      }, interval)
    }
  }

  const clearObj = (obj) => {
    for (const key in obj) {
      obj[key] = undefined
    }
  }

  function delNullAttrObj(list) {
    list.forEach((obj, idx1) => {
      Object.keys(obj).forEach((item) => {
        if (isEmpty(obj[item])) {
          delete list[idx1]
        }
      })
    })

    return list.filter(Boolean)
  }

  const goHome = () => {
    app.$router.push({ path: Routes.home })
  }

  const clearLocalStorage = () => {
    localStorage.removeItem('order_list_screenData')
  }

  const allFuncs = {
    // 值
    order_manager_info_template,
    deliver_manager_info_template,
    price_list_info_template,
    isAdmin,
    isCust,
    isCustSpm,
    isCustPm,
    isSupSpm,
    isSupPm,
    isSup,
    customers,
    route: Routes,
    // 方法
    goHome,
    delNullAttrObj,
    deepClone,
    clearObj,
    defined,
    BiMap,
    debounce,
    type,
    watchVal,
    findDom,
    includes,
    zero,
    sum,
    tofixed2,
    modifyRoute,
    getMatched,
    setData,
    resetData,
    del,
    strLen,
    limitStr,
    returnLogin,
    isEmpty,
    noEmpty,
    noEmptys,
    is,
    allEqual,
    allKeyEqual,
    elementContains,
    sortClass,
    sort,
    $$,
    unique_obj_arr,
    unique,
    flatten,
    clearLocalStorage,
  }

  return allFuncs
}

// 表格滚动轴宽度
const scrollXWidth = window.innerWidth < 1920 ? 1624 : 0

const $g = myLib()

$g.scrollXWidth = scrollXWidth

export default $g
